/* eslint-disable */
<template>
  <div
    v-if="
      this.$store.state.isLoggedIn && this.$store.state.role === 'proveedor'
    "
    class="h-20 sm:h-30"
  >
    <div
      class="bg-green-800 w-full h-20 sm:h-30 flex items-center absolute z-50"
    >
      <div class="flex w-full p-2 justify-between">
        <div class="flex items-center">
          <button @click="isOpen = !isOpen" class="focus:outline-none">
            <svg
              height="334pt"
              viewBox="0 -53 384 384"
              width="334pt"
              :class="isOpen ? 'bg-green-500' : 'bg-auto'"
              class="fill-white h-10 w-10 sm:h-12 sm:w-12 rounded-md hover:bg-green-500 p-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
              />
              <path
                d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
              />
              <path
                d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
              />
            </svg>
          </button>
        </div>
        <div class="">
          <router-link to="/"
            ><img
              class="w-18 h-16 sm:h-16 sm:w-24"
              src="@/assets/iconos/iconoMenu.png"
              alt="shoppers-logo"
          /></router-link>
        </div>
        <div class="flex items-center">
          <button
            class="fill-white hover:bg-green-500 rounded-md"
            :class="$store.state.openSearch ? 'bg-green-500' : 'bg-auto'"
            @click="searchView"
          >
            <img
              class="w-10 h-10"
              src="@/assets/iconos/search.svg"
              alt="search"
            />
          </button>
        </div>
      </div>
    </div>
    <div
      class="sm:w-full sm:h-auto flex items-center justify-center"
      @click="isOpen = !isOpen"
      :class="isOpen ? 'flex' : 'hidden'"
    ></div>
    <div class="z-10 bg-green-800 absolute w-full mt-20 sm:mt-30">
      <transition name="expand">
        <burgerMenu v-if="isOpen" class="w-full" @closeBurgerMenu="closeBurgerMenu"/>
      </transition>
    </div>
  </div>
</template>
<script>
import burgerMenu from "@/components/burguerMenu";

export default {
  name: "cabeceraProveedor",
  components: {
    burgerMenu,
  },
  data() {
    return {
      isOpen: false,
      notificar: false,
      showTable: false,
      openSearch: false,
      notificacionesObject: [],
      me: "",
      user_id: 0,
      mensajeLlegada: {},
    };
  },
  async created() {
    /*    //Query para determinar el id del usuario
    await this.queryIdUsuario();
    //Canal individual
    window.Echo.private("shoppers." + this.me).listen(
      "TipoCambioUpdate",
      (e) => {
        //Validacion para no repetir mensaje en modal de notificaciones
        //if (e.mensaje.message != this.mensajeAnterior) {

        this.notificacionesObject.splice(0, 0, {
          message: this.mensajeLlegada.message,
          id: this.mensajeLlegada.id,
          check: this.mensajeLlegada.check,
          compras_id: this.mensajeLlegada.compras_id,
          tasa_cambio: this.mensajeLlegada.tasa_cambio,
          cotizacion_id: this.mensajeLlegada.cotizacion_id,
        });
        this.notificar = true;
        //splice para borrar el duplicado del mensaje
        this.notificacionesObject.splice(0, 1);
      }
    ); */
  },
  methods: {
    closeBurgerMenu(){
      this.isOpen = false;
    },
    searchView() {
      this.$store.state.openSearch = !this.$store.state.openSearch;
      if (this.$store.state.openSearch) {
        this.$store.state.selectedRubro.selectedOld =
          this.$store.state.selectedRubro.selected;
        this.$store.state.selectedRubro.selected = "todos";
      } else {
        this.$store.state.selectedRubro.selected =
          this.$store.state.selectedRubro.selectedOld;
      }
    },

    /*     async queryIdUsuario() {
      await this.$apollo
        .query({
          query: require("@/graphql/queries/idUsuario.gql"),
        })
        .then((data) => {
          this.me = data.data.me.id;
          this.queryNotificaciones();
        })
        .catch((error) => {
          console.log(error);
        });
    }, */
    /*     async queryNotificaciones() {
      await this.$apollo
        .query({
          query: require("@/graphql/queries/notificacionesQuery.gql"),
          variables: {
            id: parseInt(this.me),
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          this.notificacionesObject = data.data.notificacionesQuery;
        })
        .catch((error) => {
          console.log(error);
        });
    }, */
  },
};
</script>

<style scoped>
.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.25s ease;
  max-height: 20rem;
}
.expand-enter,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
