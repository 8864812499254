<template>
  <div>
    <div class="flex justify-center px-3">
      <transition name="expand">
        <input
          v-if="$store.state.openSearch"
          class="w-full border-green-300 border-2 text-xl rounded-md my-4 pl-2"
          type="text"
          placeholder="Buscar producto..."
          v-model="$store.state.searchInput"
        />
      </transition>
    </div>
    <transition name="fade">
      <home-header
        v-show="!$store.state.openSearch"
        :proveedorDataPropObject="proveedorDataObject"
        :rubrosList="rubroSelectArray"
        :dataValidationProp="dataValidation"
        @setCheckboxValParent="setCheckboxVal"
        @rubroSelectEvent="rubroChange"
        @nuevoDescuentoGeneral="cambioDescuentoGeneral"
      />
    </transition>
    <transition name="fade">
      <div v-show="proveedorDataObject.disponible">
        <span v-for="(producto, k) in filteredList" :key="k">
          <transition name="fade">
            <div v-if="$store.state.selectedRubro.selected === 'todos'">
              <ProductoCard
                :productoObjectProp="producto"
                :key="componentKeyProducto"
              />
            </div>
            <div v-else>
              <transition name="fade">
                <div
                  v-if="producto.rubro === $store.state.selectedRubro.selected"
                >
                  <ProductoCard
                    :productoObjectProp="producto"
                    :key="componentKeyProducto"
                  />
                </div>
              </transition>
            </div>
          </transition>
        </span>
      </div>
    </transition>
    <div class="flex justify-center w-full my-12">
      <button
        class="border sticky border-green-400 bg-green-500 hover:bg-green-600 hover:border-green-500 text-2xl text-white w-10/12 sm:w-96 rounded-2xl shadow-lg"
        @click="guardarData()"
      >
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
import ProductoCard from "@/components/ProductsCard";
import getIdMixin from "../mixins/getIdMixin";
import HomeHeader from "../components/homeHeader.vue";

export default {
  components: {
    ProductoCard,
    HomeHeader,
  },
  mixins: [getIdMixin],
  data() {
    return {
      productArray: [],
      proveedorDataObject: {
        tasaDia: 0,
        descuento: 0,
        monedaUSD: true,
        disponible: true,
      },
      rubroSelectArray: [],
      componentKeyProducto: 0,
      componentKey: 0,
      dataValidation: {
        tasaDia: true,
        descuento: true,
      },
    };
  },
  async created() {
    this.$store.state.openSearch = false;
    await this.listaProductos();
  },
  methods: {
    cambioDescuentoGeneral(event) {
      if (!isNaN(parseFloat(event.descuentoNew))) {
        this.productArray.map((x, index) => {
          if (parseFloat(x.descuento) === parseFloat(event.descuentoOld)) {
            this.productArray[index].descuento = parseFloat(event.descuentoNew);
          }
        });
        this.proveedorDataObject.descuentoOld = parseFloat(event.descuentoNew);
      }
    },
    rubroChange(event) {
      this.$store.state.selectedRubro.selected = event.rubro;
    },
    setCheckboxVal(event) {
      if (event.name == "disponibilidad") {
        this.proveedorDataObject.disponible = event.value;
      }
      if (event.name == "tipoMoneda") {
        this.proveedorDataObject.monedaUSD = event.value;
        this.productArray.map((x) => {
          x.monedaUSD = event.value;
          return x;
        });
        this.componentKeyProducto++;
      }
    },
    async listaProductos() {
      this.$store.state.isLoading = true;
      if (this.$store.state.user.id == 0) {
        await this.getUserId();
      }
      await this.$apollo
        .query({
          query: require("@/graphql/queries/proveedorLista.gql"),
          variables: {
            id: this.$store.state.user.id,
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          //rubros del proveedor
          let dataRubroArray = [];
          console.log("aqui",data.data.proveedorLista);
          dataRubroArray = data.data.proveedorLista.precio.map((x) => {
            return x.producto_info.rubro;
          });

          this.rubroSelectArray = dataRubroArray.filter((item, index) => {
            return dataRubroArray.indexOf(item) === index;
          });
          this.rubroSelectArray.unshift("todos");
          //productos proveedor
          this.productArray = data.data.proveedorLista.precio.map((x) => {
            return {
              descuento: parseFloat(x.descuento),
              disponibilidad: this.intToBoolean(parseInt(x.existencia)),
              id: parseInt(x.id),
              precioBs: parseFloat(x.precio_bs),
              precio_usd: parseFloat(x.precio_usd),
              productoNombre: x.producto_info.producto,
              unidad: x.unidad,
              monedaUSD: this.intToBoolean(data.data.proveedorLista.pivote_usd),
              tasaCambio: data.data.proveedorLista.tasa_dia,
              rubro: x.producto_info.rubro,
              dataValidated: true,
            };
          });
          //re render product card component
          this.componentKeyProducto++;
          this.proveedorDataObject.tasaDia = data.data.proveedorLista.tasa_dia;
          this.proveedorDataObject.monedaUSD =
            data.data.proveedorLista.pivote_usd;
          this.proveedorDataObject.disponible = data.data.proveedorLista.show;

          this.proveedorDataObject.descuento = parseFloat(
            data.data.proveedorLista.descuento_general
          );

          this.proveedorDataObject.descuentoOld = parseFloat(
            data.data.proveedorLista.descuento_general
          );
          this.$store.state.isLoading = false;
        })
        .catch((error) => {
          this.$store.state.isLoading = false;
          console.log(error);
        });
    },
    intToBoolean(number) {
      if (number == 1) {
        return true;
      } else {
        return false;
      }
    },
    async guardarData() {
      if (confirm("¿Desea guardar los cambios?")) {
        if (!this.validarData()) {
          let objectMutation = {
            descuento_general: parseFloat(this.proveedorDataObject.descuento),
            tasa_dia: parseFloat(this.proveedorDataObject.tasaDia),
            show: this.proveedorDataObject.disponible,
            pivote_usd: this.proveedorDataObject.monedaUSD,
            precio_lista: this.productArray.map((x) => {
              return {
                id: parseInt(x.id),
                descuento: parseFloat(x.descuento),
                existencia: x.disponibilidad,
                precio_bs: parseFloat(x.precioBs),
                precio_usd: parseFloat(x.precio_usd),
              };
            }),
          };

          this.$store.state.isLoading = true;
          await this.$apollo
            .mutate({
              mutation: require("@/graphql/mutations/actualizarListaProductoProveedor.gql"),
              variables: {
                data: objectMutation,
                id: this.$store.state.user.id,
              },
            })
            .then(() => {
              this.$store.state.isLoading = false;
              alert("¡Data actualizada!");
              this.listaProductos();
            })
            .catch((error) => {
              this.$store.state.isLoading = false;
              console.error(error);
              alert("Error al guardar lista, vuelva a intentarlo.");
            });
        } else {
          alert(
            "Error al guardar: data ingresada no valida, por favor verificar y corregir"
          );
        }
      }
    },
    validarData() {
      //validar data tasa y descuento
      let invalidadData = false;
      this.dataValidation.tasaDia = true;
      this.dataValidation.descuento = true;
      if (
        isNaN(this.proveedorDataObject.tasaDia) ||
        this.proveedorDataObject.tasaDia === "" ||
        parseFloat(this.proveedorDataObject.tasaDia) <= 0
      ) {
        invalidadData = true;
        this.dataValidation.tasaDia = false;
      }
      if (
        isNaN(this.proveedorDataObject.descuento) ||
        this.proveedorDataObject.descuento === "" ||
        parseFloat(this.proveedorDataObject.descuento) < 0 ||
        parseFloat(this.proveedorDataObject.descuento) > 100
      ) {
        invalidadData = true;
        this.dataValidation.descuento = false;
      }
      //Validar Array producto
      this.productArray.map((x) => {
        x.dataValidated = true;
        if (
          isNaN(x.descuento) ||
          parseFloat(x.descuento) < 0 ||
          parseFloat(x.descuento) > 100 ||
          isNaN(x.precioBs) ||
          parseFloat(x.precioBs < 0) ||
          isNaN(x.precio_usd) ||
          parseFloat(x.precio_usd < 0)
        ) {
          x.dataValidated = false;
          invalidadData = true;
        }
        return x;
      });
      return invalidadData;
    },
  },
  computed: {
    filteredList() {
      if (this.$store.state.openSearch) {
        return this.productArray.filter((product) => {
          this.componentKeyProducto++;
          return product.productoNombre
            .toLowerCase()
            .includes(this.$store.state.searchInput.toLowerCase());
        });
      } else {
        return this.productArray.filter((product) => {
          this.componentKeyProducto++;
          return product.productoNombre;
        });
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
