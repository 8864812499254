<template>
  <div
    class="flex items-center justify-center pb-0 mt-1 border rounded-l text-xl text-white"
  >
    <ul class="w-full px-0">
      <li
        class="text-center py-3 border-b hover:bg-green-200 hover:text-gray-700 font-bold"
        v-for="(item, index) in Menu"
        :key="index"
        @click="goNewView(item.link)"
      >
        {{ item.nombre }}
      </li>
      <li
        class="text-center py-5 hover:bg-green-200 hover:text-gray-700 font-bold"
      >
        <a href="/login" @click="logout">Cerrar Sesion</a>
      </li>
    </ul>
  </div>
</template>
<script>
import { onLogout } from "../vue-apollo.js";
export default {
  data() {
    return {
      Menu: [
        { nombre: "Editar Lista de precios", link: "/" },
        {
          nombre: "Enviar/Descargar lista de precios",
          link: "/generar_lista_precios",
        },
        { nombre: "Crear/Editar emails clientes", link: "/clients_emails" },
        { nombre: "Cuentas por cobrar", link: "/cxc" },
        { nombre: "Editar Perfil", link: "/editar_perfil" },
      ],
    };
  },
  methods: {
    goNewView(arg) {
      this.$router.push({
        path: arg,
      });
      this.$emit("closeBurgerMenu");
    },
    logout() {
      this.$store.state.user.id = 0;
      this.$store.state.user.nameUser = "";
      this.$store.state.user.nameLocal = "";
      localStorage.removeItem("vau");
      this.$store.commit("whenLoginOut");
      this.$store.state.isLoggedIn = false;
      onLogout(this.$apollo.provider.defaultClient);
    },
  },
};
</script>
