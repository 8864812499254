export default {
  async created() {
    if (this.$store.state.user.id == 0) {
      await this.getUserId();
    }
  },
  methods: {
    async getUserId() {
      await this.$apollo
        .query({
          query: require("@/graphql/queries/getLocalId.gql"),
        })
        .then((data) => {
          this.$store.state.user.id = parseInt(data.data.me.locales[0].id);
          this.$store.state.user.nameUser = data.data.me.name;
          this.$store.state.user.nameLocal = data.data.me.locales[0].nombre;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
