import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    titulo: "Dashboard",
    isLoggedIn: false,
    openSearch: false,
    loginData: {
      role: "",
      token: "",
    },
    isLoading: false,
    selectedRubro: {
      selected: "todos",
      selectedOld: "",
    },
    searchInput: "",
    user: {
      id: 0,
      nameUser: "",
      nameLocal: "",
    },
    clientDataValidated: true,
  },
  plugins: [createPersistedState()],
  mutations: {
    crearDataPdf(state, payload) {
      state.pdfData = payload;
    },
    whenLoginIn(state) {
      let auth = JSON.parse(localStorage.getItem("vau"));

      state.isLoggedIn = true;
      state.loginData.role = auth.role;
      state.loginData.token = auth.token;
      localStorage.removeItem("vau");
    },
    initialiseStore(state) {
      if (localStorage.getItem("vau")) {
        let auth = JSON.parse(localStorage.getItem("vau"));
        state.isLoggedIn = true;
        state.loginData.role = auth.role;
        state.loginData.token = auth.token;
        localStorage.removeItem("vau");
      }
    },
    whenLoginOut(state) {
      localStorage.setItem("loggedIn", false);
      state.isLoggedIn = false;
      state.loginData.role = "";
      state.loginData.token = "";
    },
  },
  actions: {},
  modules: {},
});
