import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Homepage.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      proveedorAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
  },
  {
    path: "/editar_perfil",
    name: "EditarPerfil",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "EditarPerfil" */ "../views/EditarPerfil.vue"
      ),
    meta: {
      requiresAuth: true,
      proveedorAuth: true,
    },
  },
  {
    path: "/cxc",
    name: "CuentasCobrar",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "CuentasCobrar" */ "../views/CuentasCobrar.vue"
      ),
    meta: {
      requiresAuth: true,
      proveedorAuth: true,
    },
  },
  {
    path: "/generar_lista_precios",
    name: "GenerarListaPrecios",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "GenerarListaPrecios" */ "../views/GenerarListaPrecios.vue"
      ),
    meta: {
      requiresAuth: true,
      proveedorAuth: true,
    },
  },
  {
    path: "/clients_emails",
    name: "SaveEmails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "SaveEmails" */ "../views/SaveEmails.vue"),
    meta: {
      requiresAuth: true,
      proveedorAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const authUser = store.state.loginData;
    if (authUser === "" || authUser.token === "") {
      next({ name: "Login" });
    } else {
      if (to.meta.proveedorAuth) {
        if (authUser.role === "proveedor") {
          next();
        } else {
          next({ name: "404" });
        }
      } else {
        next({ name: "404" });
      }
    }
  } else {
    next();
  }
});

export default router;
