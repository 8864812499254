<template>
  <div
    class="m-3 border-2 rounded-md shadow-lg"
    :class="productoData.disponibilidad ? 'border-green-200' : 'border-red-400'"
  >
    <div :class="showCard ? cardHeight : 'h-auto'">
      <button class="w-full" @click="rotate">
        <div
          class="flex justify-between w-full items-center p-2"
          :class="cardHeight"
        >
          <div
            class="flex justify-between w-full items-center"
            :class="productoData.dataValidated ? '' : 'border-4 border-red-800'"
          >
            <label class="w-36 text-lg" for="productoData.producto">{{
              firstLetterUpperCase(productoData.productoNombre) +
              " (" +
              firstLetterUpperCase(productoData.unidad) +
              ")"
            }}</label>
            <label class="text-xl" v-if="showUSD" for="precio_usd">{{
              String(formatoNumeroDosDecimalesUSD(productoData.precio_usd)) +
              " $"
            }}</label>
            <label v-else class="text-xl" for="productoData.precioBs">{{
              String(formatoNumeroDosDecimalesUSD(productoData.precioBs)) +
              " Bs"
            }}</label>

            <img
              :class="classes"
              class="w-5 h-5"
              src="../assets/iconos/arrow.svg"
              alt="arrow"
            />
          </div>
        </div>
      </button>
    </div>
    <!-- card extension  -->
    <transition name="expand">
      <div class="p-2 border-t-2 mt-4" v-if="!showCard">
        <div class="mt-2">
          <div class="flex justify-between items-center mb-4 mr-4">
            <h4 class="text-xl mr-2">Precio:</h4>
            <div class="flex w-40">
              <input
                class="text-xl border-2 pl-2 w-36 border-green-300 rounded-md"
                type="number"
                v-model="precioInput"
                @keyup="convertirMoneda()"
                step="0.01"
                name=""
                id=""
                min="0"
                @wheel="$event.target.blur()"
                @keydown="queryForKeywords"
              />
              <h4 v-if="!productoData.monedaUSD" class="text-xl ml-2">Bs</h4>
              <h4 v-else class="text-xl ml-2">$&nbsp;</h4>
            </div>
          </div>
          <div class="">
            <label class="text-xl" v-if="productoData.monedaUSD" for=""
              >Precio:
              {{
                String(
                  formatoNumeroDosDecimalesUSD(
                    parseFloat(precioInput) *
                      parseFloat(productoData.tasaCambio)
                  )
                ) + " Bs"
              }}</label
            >
            <label class="text-xl" v-else for=""
              >Precio:
              {{
                String(
                  formatoNumeroDosDecimalesUSD(
                    parseFloat(precioInput) /
                      parseFloat(productoData.tasaCambio)
                  )
                ) + " $ "
              }}</label
            >
          </div>
          <div class="w-full border-t-2 my-4"></div>
          <div class="flex justify-between items-center mr-1">
            <h4 class="text-xl">Moneda:</h4>
            <div class="flex items-center">
              <h4 class="text-xl mr-2">Bs</h4>
              <togglebutton
                :initialState="productoData.monedaUSD"
                :checkboxName="'tipoMoneda'"
                @setCheckboxVal="setCheckboxVal"
              />
              <h4 class="text-xl ml-2">$</h4>
            </div>
          </div>
          <div class="w-full border-t-2 my-4"></div>
          <div class="flex justify-between items-center">
            <h4 class="text-xl">Disponible:</h4>
            <div class="flex">
              <h4 class="text-xl mr-2">No</h4>
              <togglebutton
                :initialState="productoData.disponibilidad"
                :checkboxName="'disponibilidad'"
                @setCheckboxVal="setCheckboxVal"
              />
              <h4 class="text-xl ml-2">Si</h4>
            </div>
          </div>
          <div class="w-full border-t-2 my-4"></div>

          <div class="flex justify-between items-center mb-4 mr-4">
            <h4 class="text-xl mr-2">Descuento:</h4>
            <input
              class="text-xl border-2 pl-2 w-24 border-green-300 rounded-md"
              type="number"
              v-model="productoData.descuento"
              name=""
              id=""
              min="0"
              step="0.01"
              max="100"
              @wheel="$event.target.blur()"
              @keydown="queryForKeywords"
            />
            <h4 class="text-xl ml-2">%</h4>
          </div>
          <div v-if="productoData.monedaUSD" class="mb-8">
            <h4 class="text-xl">
              P. Desc.:
              {{
                String(
                  formatoNumeroDosDecimalesUSD(
                    parseFloat(precioInput) *
                      parseFloat(productoData.tasaCambio) *
                      (1 - parseFloat(productoData.descuento) / 100)
                  )
                ) + " Bs"
              }}
            </h4>
            <h4 class="text-xl">
              P. Desc.:
              {{
                String(
                  formatoNumeroDosDecimalesUSD(
                    parseFloat(precioInput) *
                      (1 - parseFloat(productoData.descuento) / 100)
                  )
                ) + " $ "
              }}
            </h4>
          </div>
          <div v-else class="mb-8">
            <h4 class="text-xl">
              P. Desc.:
              {{
                String(
                  formatoNumeroDosDecimalesUSD(
                    parseFloat(precioInput) *
                      (1 - parseFloat(productoData.descuento) / 100)
                  )
                ) + " Bs"
              }}
            </h4>
            <h4 class="text-xl">
              P. Desc.:
              {{
                String(
                  formatoNumeroDosDecimalesUSD(
                    (parseFloat(precioInput) /
                      parseFloat(productoData.tasaCambio)) *
                      (1 - parseFloat(productoData.descuento) / 100)
                  )
                ) + " $ "
              }}
            </h4>
          </div>
        </div>
      </div></transition
    >
  </div>
</template>

<script>
import {
  firstLetterUpperCase,
  formatoNumeroDosDecimalesUSD,
} from "@/functions.js";
import togglebutton from "@/components/ToggleButton.vue";

export default {
  props: {
    productoObjectProp: Object,
  },
  components: {
    togglebutton,
  },
  data() {
    return {
      showUSD: true,
      showCard: true,
      classes: "",
      precioInput: 0,
      productoData: {
        productoNombre: "",
        unidad: "",
        dataValidated: true,
      },
      cardHeight: "",
    };
  },
  mounted() {
    //card data
    this.productoData = this.productoObjectProp;
    this.showUSD = this.productoObjectProp.monedaUSD;
    //input data
    if (this.productoData.monedaUSD) {
      this.precioInput = parseFloat(this.productoData.precio_usd);
    } else {
      this.precioInput =
        parseFloat(this.productoData.precio_usd) *
        parseFloat(this.productoData.tasaCambio);
    }
    //card size classes
    if (this.productoData.productoNombre.length <= 25) {
      this.cardHeight = "h-20";
    }
    if (
      this.productoData.productoNombre.length > 25 &&
      this.productoData.productoNombre.length < 40
    ) {
      this.cardHeight = "h-26";
    }
    if (this.productoData.productoNombre.length >= 40) {
      this.cardHeight = "h-auto";
    }
  },
  methods: {
    firstLetterUpperCase,
    formatoNumeroDosDecimalesUSD,
    queryForKeywords(e) {
      const value = e.target.value;

      this.keywords = value;
      
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    },
    convertirMoneda() {
      if (this.productoData.monedaUSD) {
        this.productoData.precio_usd = this.formatoNumeroDosDecimalesUSD(
          this.precioInput
        );
        this.productoData.precioBs = this.formatoNumeroDosDecimalesUSD(
          parseFloat(this.precioInput) *
            parseFloat(this.productoData.tasaCambio)
        );
      } else {
        this.productoData.precio_usd = this.formatoNumeroDosDecimalesUSD(
          parseFloat(this.precioInput) /
            parseFloat(this.productoData.tasaCambio)
        );
        this.productoData.precioBs = this.formatoNumeroDosDecimalesUSD(
          parseFloat(this.precioInput)
        );
      }
    },
    setCheckboxVal(event) {
      if (event.name == "disponibilidad") {
        this.productoData.disponibilidad = event.value;
      }
      if (event.name == "tipoMoneda") {
        this.productoData.monedaUSD = event.value;
        if (this.productoData.monedaUSD) {
          this.precioInput = parseFloat(this.productoData.precio_usd);
        } else {
          this.precioInput = parseFloat(this.productoData.precioBs);
        }
      }
    },
    rotate() {
      if (this.showCard) {
        this.classes = "rotate-arrow-enter";
      } else {
        this.classes = "rotate-arrow-leave-to";
      }
      this.showCard = !this.showCard;
    },
  },
};
</script>

<style scope>
.rotate-arrow-enter, .rotate-arrow-leave-to
/* .rotate-arrow-leave-active below version 2.1.8 */ {
  transform: rotate(180deg);
  transition: all 0.3s;
}
.rotate-arrow-leave-to
/* .rotate-arrow-leave-active below version 2.1.8 */ {
  transform: rotate(0deg);
  transition: all 0.3s;
}

.expand-enter-active {
  transition: max-height 0.25s ease;
  max-height: 24rem;
}
.expand-leave-active {
  transition: max-height 0.1s ease;
  max-height: 24rem;
}
.expand-enter,
.expand-leave-to {
  max-height: 0;
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
