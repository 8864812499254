<template>
  <div class="w-full p-2 mt-3 mb-8 border-b-2">
    <div class="">
      <div class="flex justify-between w-full mt-4 mb-8">
        <h4 class="text-xl mr-2">Tasa dia:</h4>
        <input
          class="text-xl pl-2 w-40 rounded-md"
          :class="
            dataValidation.tasaDia
              ? 'border-green-300 border-2'
              : 'border-red-800 border-4'
          "
          type="number"
          v-model="proveedorDataObject.tasaDia"
          step="0.0001"
          name=""
          id=""
          min="0"
        />
        <h4 class="text-xl pr-2">Bs</h4>
      </div>
      <div class="flex justify-between w-full mt-4 mb-8">
        <h4 class="text-xl mr-2">Descuento:</h4>
        <input
          class="text-xl border-2 pl-2 w-36 rounded-md"
          :class="
            dataValidation.descuento
              ? 'border-green-300 border-2'
              : 'border-red-800 border-4'
          "
          type="number"
          v-model="proveedorDataObject.descuento"
          @keyup="cambioDescuento"
          step="0.01"
          name=""
          id=""
          min="0"
        />
        <h4 class="text-xl pr-2 pl-3">%</h4>
      </div>
      <div class="flex justify-between my-8 mr-2">
        <h4 class="text-xl">Moneda:</h4>
        <div class="flex">
          <h4 class="text-xl mr-2">Bs</h4>
          <togglebutton
            :initialState="proveedorDataObject.monedaUSD"
            :checkboxName="'tipoMoneda'"
            @setCheckboxVal="setCheckboxVal"
            :key="componentKeyMoneda"
          />
          <h4 class="text-xl ml-2 mr-1">$</h4>
        </div>
      </div>
      <div class="flex justify-between mt-4 mb-8 mr-2">
        <h4 class="text-xl">Disponible:</h4>
        <div class="flex justify-center">
          <h4 class="text-xl mr-2">No</h4>
          <togglebutton
            class=""
            :initialState="proveedorDataObject.disponible"
            :checkboxName="'disponibilidad'"
            @setCheckboxVal="setCheckboxVal"
            :key="componentKey"
          />
          <h4 class="text-xl ml-2">Si</h4>
        </div>
      </div>
      <div class="flex justify-between mb-8 items-center">
        <h4 class="text-xl">Rubro:</h4>
        <select
          class="text-xl bg-white border-2 border-green-300 rounded-lg pl-2 mr-2"
          name="rubroSelect"
          id="rubroSelect"
          v-model="this.$store.state.selectedRubro.selected"
          @change="rubroSelect($event)"
        >
          <option v-for="(rubro, k) in rubrosList" :key="k" :value="rubro">
            {{ firstLetterUpperCase(rubro) }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import togglebutton from "@/components/ToggleButton.vue";
import { firstLetterUpperCase } from "@/functions.js";

export default {
  props: {
    proveedorDataPropObject: Object,
    rubrosList: Array,
    dataValidationProp: Object,
  },
  components: {
    togglebutton,
  },
  data() {
    return {
      proveedorDataObject: {},
      componentKey: 0,
      componentKeyMoneda: 0,
      dataValidation: {},
    };
  },
  mounted() {
    this.proveedorDataObject = this.proveedorDataPropObject;
    this.dataValidation = this.dataValidationProp;
  },
  watch: {
    proveedorDataPropObject: {
      handler() {
        setTimeout(() => {
          this.componentKey++;
          this.componentKeyMoneda++;
        }, 400);
      },
      deep: true,
    },
  },
  methods: {
    firstLetterUpperCase,
    cambioDescuento() {
      if (
        parseFloat(this.proveedorDataObject.descuento) < 0 ||
        parseFloat(this.proveedorDataObject.descuento) > 100 ||
        isNaN(parseFloat(this.proveedorDataObject.descuento))
      ) {
        this.dataValidation.descuento = false;
      } else {
        this.dataValidation.descuento = true;
        setTimeout(() => {
          if (
            parseFloat(this.proveedorDataObject.descuento) >= 0 &&
            parseFloat(this.proveedorDataObject.descuento) <= 100 &&
            !isNaN(parseFloat(this.proveedorDataObject.descuento)) &&
            this.proveedorDataObject.descuento != ""
          ) {
            this.$emit("nuevoDescuentoGeneral", {
              descuentoNew: this.proveedorDataObject.descuento,
              descuentoOld: this.proveedorDataObject.descuentoOld,
            });
          }
        }, 2000);
      }
    },
    rubroSelect(event) {
      this.$emit("rubroSelectEvent", {
        rubro: event.target.value,
      });
    },
    setCheckboxVal(event) {
      this.$emit("setCheckboxValParent", {
        value: event.value,
        name: event.name,
      });
    },
  },
};
</script>

<style></style>
