<template>
  <label class="switch">
    <input type="checkbox" @click="toggleCheckbox" :checked="checkbox" />
    <div class="slider round"></div>
  </label>
</template>

<script>
export default {
  props: {
    initialState: Boolean,
    checkboxName: String,
  },
  data() {
    return {
      checkbox: false,
    };
  },
  mounted() {
    this.checkbox = this.initialState;
  },
  methods: {
    toggleCheckbox() {
      this.$forceUpdate();
      this.checkbox = !this.checkbox;
      this.$emit("setCheckboxVal", {
        value: this.checkbox,
        name: this.checkboxName,
      });
    },
  },
};
</script>

<style scope>
.switch {
  position: relative;
  display: inline-block;
  width: 3.5rem;
  height: 1.89rem;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  left: 0.2rem;
  bottom: 0.2rem;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #a7f3d0;
}

input:focus + .slider {
  box-shadow: 0 0 1px #a7f3d0;
}

input:checked + .slider:before {
  -webkit-transform: translateX(2rem);
  -ms-transform: translateX(2rem);
  transform: translateX(1.65rem);
}

.slider.round {
  border-radius: 2rem;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
